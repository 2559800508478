<template>
  <!-- Storyblok content -->
  <StoryblokComponent
    v-if="story && !pending && !error"
    :blok="story.content"
  />
</template>

<script setup lang="ts">
const route = useRoute()

const slug = computed(() => {
  if (Array.isArray(route.params.slug)) {
    return route.params.slug.join('/')
  }
  else {
    return route.params.slug
  }
})

// Load content from Storyblok
const { story, pending, error, setSEO } = useStoryblokCMS(slug.value)

// Set seo meta
setSEO(story)

// Set json ld
useJsonld({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  'name': 'BeyondBreath',
  'legalName': 'BeyondBreath',
  'description': 'Feel mentally & emotionally better within minutes. Breathwork audio sessions for home guided by world-class coaches',
  'logo': 'https://www.beyondbreath.com/logo.png',
  'url': 'https://www.beyondbreath.com',
  'sameAs': [
    'https://twitter.com/BeyondBreathapp',
    'https://www.instagram.com/beyondbreathapp/',
    'https://www.facebook.com/beyondbreathapp/',
    'https://play.google.com/store/apps/details?id=com.beyondbreath.androidapp&hl=de',
    'https://apps.apple.com/us/app/beyondbreath-guided-breathwork/id6498632607',
  ],
})
</script>
